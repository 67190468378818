import { BigNumber } from '@ethersproject/bignumber'
import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { USDC, USDT, bscTestnetTokens, bscTokens, dogechainTokens, shibariumTokens, ethTokens } from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList, DexChainMap } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.BSC]: '0x9695906B4502D5397E6D21ff222e2C1a9e5654a9',
  [ChainId.BSC_TESTNET]: '0x8206310C8B33EAef1eF37D6D95272B442eBD20bF',
  [ChainId.SHIBARIUM]: '0x2875F2D86d83635A859029872e745581530cEec7',
  [ChainId.DOGECHAIN]: '0x45AFCf57F7e3F3B9cA70335E5E85e4F77DcC5087',
  [ChainId.ETH]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
}


export const DEX_CONFIG: DexChainMap = {
  [ChainId.SHIBARIUM]: {
    default: {
      id: 0,
      displayName: 'ChewySwap',
      factory: '0xEDedDbde5ffA62545eDF97054edC11013ED72125',
      router: '0x2875F2D86d83635A859029872e745581530cEec7',
      lpName: 'ChewySwap LPs',
      lpSymbol: 'Chewy-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0xa2ef75f706f4aa0c026dd9371dab1bac975a91bc8f0852a86e6f330eedd30848',
    },
    shibaswap: {
      id: 1,
      displayName: 'ShibaSwap',
      factory: '0xc2b4218f137e3a5a9b98ab3ae804108f0d312cbc',
      router: '0xEF83bbB63E8A7442E3a4a5d28d9bBf32D7c813c8',
      lpName: 'ShibaSwap LP Token',
      lpSymbol: 'SSLP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '65d1a3b1e46c6e4f1be1ad5f99ef14dc488ae0549dc97db9b30afe2241ce1c7a',
    },
    marswap: {
      id: 2,
      displayName: 'Marswap',
      factory: '0xChewySwapFactoryAddressOnShibarium',
      router: '0xChewySwapRouterAddressOnShibarium',
      lpName: 'MarSwap LPs',
      lpSymbol: 'Mars-LP',
      defunct: true,
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
    }
  },
  [ChainId.DOGECHAIN]: {
    default: {
      id: 0,
      displayName: 'ChewySwap',
      factory: '0xUniswapFactoryAddressOnDogeChain',
      router: '0xUniswapRouterAddressOnDogeChain',
      lpName: 'Uniswap LP',
      lpSymbol: 'UNI-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0xb336a8b47fdc2600ed0b1be659f72b59cb3096a8dd3814eda610401c00f5fd49',
    },
    dogswap: {
      id: 1,
      displayName: 'DogSwap',
      factory: '0xChewySwapFactoryAddressOnDogeChain',
      router: '0xChewySwapRouterAddressOnDogeChain',
      lpName: 'ChewySwap LP',
      lpSymbol: 'CHEWY-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
    },
  },
  [ChainId.BSC_TESTNET]: {
    default: {
      id: 0,
      displayName: 'ChewySwap',
      factory: '0xEDedDbde5ffA62545eDF97054edC11013ED72125',
      router: '0x2875F2D86d83635A859029872e745581530cEec7',
      lpName: 'ChewySwap LPs',
      lpSymbol: 'Chewy-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0x4455b31327401d7d38602d2b84b8e6a3843289a04fa0ff3ef5195bb4a43f0688',
    },
  },
  [ChainId.BSC]: {
    default: {
      id: 0,
      displayName: 'ChewySwap',
      factory: '0xEDedDbde5ffA62545eDF97054edC11013ED72125',
      router: '0x2875F2D86d83635A859029872e745581530cEec7',
      lpName: 'ChewySwap LPs',
      lpSymbol: 'Chewy-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0x4455b31327401d7d38602d2b84b8e6a3843289a04fa0ff3ef5195bb4a43f0688',
    },
  },
  [ChainId.ETH]: {
    default: {
      id: 0,
      displayName: 'ChewySwap',
      factory: '0xEDedDbde5ffA62545eDF97054edC11013ED72125',
      router: '0x2875F2D86d83635A859029872e745581530cEec7',
      lpName: 'ChewySwap LPs',
      lpSymbol: 'Chewy-LP',
      factoryAbi: 'test',
      routerAbi: 'test',
      initCodeHash: '0x4455b31327401d7d38602d2b84b8e6a3843289a04fa0ff3ef5195bb4a43f0688',
    },
  },
  // Add more chain configurations as required
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.BSC]: [
    bscTokens.wbnb,
    bscTokens.wojk,
    bscTokens.busd,
    bscTokens.usdt,
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdc,
  ],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb],
  [ChainId.SHIBARIUM]: [shibariumTokens.usdt, shibariumTokens.wbone, shibariumTokens.chewy, shibariumTokens.shib, shibariumTokens.leash, shibariumTokens.treat],
  [ChainId.DOGECHAIN]: [dogechainTokens.wdoge, dogechainTokens.kibby, dogechainTokens.dc, dogechainTokens.dogecorn, dogechainTokens.dogeshrek, dogechainTokens.tdh, dogechainTokens.dtools, dogechainTokens.usdt, dogechainTokens.usdc],
  [ChainId.ETH]: [ethTokens.wEth, USDC[ChainId.ETH], USDT[ChainId.ETH]],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.BSC]: [bscTokens.busd, bscTokens.wojk, bscTokens.btcb],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.SHIBARIUM]: [shibariumTokens.chewy, shibariumTokens.shib, shibariumTokens.leash],
  [ChainId.DOGECHAIN]: [dogechainTokens.dogeshrek, dogechainTokens.dc],
  [ChainId.ETH]: [ethTokens.wEth, USDT[ChainId.ETH]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.SHIBARIUM]: [shibariumTokens.usdt, shibariumTokens.wbone, shibariumTokens.chewy, shibariumTokens.shib, shibariumTokens.treat, shibariumTokens.leash],
  [ChainId.DOGECHAIN]: [dogechainTokens.wdoge, dogechainTokens.kibby, dogechainTokens.dc, dogechainTokens.dogecorn, dogechainTokens.dogeshrek, dogechainTokens.tdh, dogechainTokens.dtools, dogechainTokens.usdt, dogechainTokens.usdc],
  [ChainId.ETH]: [ethTokens.wEth, USDT[ChainId.ETH]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.BSC]: [
    [bscTokens.wojk, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
  [ChainId.DOGECHAIN]: [
    [dogechainTokens.dogeshrek, dogechainTokens.dc],
    [dogechainTokens.dogecorn, dogechainTokens.chewy],
  ],
  [ChainId.SHIBARIUM]: [
    [shibariumTokens.chewy, shibariumTokens.shib],
    [shibariumTokens.leash, shibariumTokens.wbone],
  ],
  [ChainId.ETH]: [[ethTokens.wEth, USDT[ChainId.ETH]], [ethTokens.wEth, USDT[ChainId.ETH]],],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(800), BIPS_BASE) // 8%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

export const DEFAULT_INPUT_CURRENCY = 'BONE'
export const DEFAULT_OUTPUT_CURRENCY = '0x2761723006d3Eb0d90B19B75654DbE543dcd974f'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = ''
export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
