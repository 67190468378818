import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'
import { USDC_DC, USDT_DC } from './common'

export const dogechainTokens = {
  wdoge: WETH9[ChainId.DOGECHAIN],
  usdc: USDC_DC,
  usdt: USDT_DC,
  wojk: new Token(
    ChainId.DOGECHAIN,
    '0x570C41a71b5e2cb8FF4445184d7ff6f78A4DbcBD',
    18,
    'WOJK',
    'WojakToken',
    'https://www.wojak.fi/',
  ),
  syrup: new Token(
    ChainId.DOGECHAIN,
    '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://chewyswap.dog/',
  ),
  chewy: new Token(
    ChainId.SHIBARIUM,
    '0x1b47A8ae20fC5DC87fe566E97E693Fa6c761C851',
    18,
    'CHEWY',
    'ChewySwap',
    'https://chewyswap.dog/',
  ),
  rekt: new Token(ChainId.DOGECHAIN, '0x7fC009aDC0B7A5E9C81F2e0E7a14c6c281ABb99C', 18, 'REKT', 'Unrektus', ''),
  dc: new Token(ChainId.DOGECHAIN, '0x7B4328c127B85369D9f82ca0503B000D09CF9180', 18, 'DC', 'Dogechain Token', ''),
  dogeshrek: new Token(ChainId.DOGECHAIN, '0x2BE0096B24343549E34224aa9aa297E99961023D', 18, 'DogeShrek', 'Dogeshrek Token', ''),
  dogecorn: new Token(ChainId.DOGECHAIN, '0x8df9B21945ebaa75424730F85eCFf426C35F5EF8', 18, 'Dogecorn', 'Dogecorn', ''),
  das: new Token(ChainId.DOGECHAIN, '0xF4413B0576048c39E8190C8680A1d6c4108e6C1E', 18, 'DAS', 'DarkSwap Token', ''),
  tdh: new Token(ChainId.DOGECHAIN, '0x35EA0c670eD9f54Ac07B648aCF0F2EB173A6012D', 18, 'TDH', 'TDH', ''),
  kibby: new Token(ChainId.DOGECHAIN, '0x72aB1BAbED0502B08225FA1eF777fa673d82Ee3e', 9, 'Kibby', 'Kibby', ''),
  dtools: new Token(ChainId.DOGECHAIN, '0xB9fcAa7590916578087842e017078D7797Fa18D0', 18, 'DTools', 'DogeTools', ''),
  bbyoda: new Token(ChainId.DOGECHAIN, '0xd8ba3140C1b72432076f28aBf228755645b6A589', 9, 'BBYoda', 'Baby BabyYoda', ''),
  fuckpepe: new Token(ChainId.DOGECHAIN, '0xA6D7137aF64280e3eb8715aB6766740984Dd35E7', 9, 'FUCKPEPE', 'FUCK PEPE', ''),
  aubergine: new Token(ChainId.DOGECHAIN, '0x539Cf28f180382E5f806c8DFd6Bb9c9FA4331c8c', 18, '🍆', 'AUBERGINE', ''),
  konra: new Token(ChainId.DOGECHAIN, '0x4716269c75C09eaA3Df3Cc1709A88C9Da663f272', 9, 'KONRA', 'KonrA', ''),
}
