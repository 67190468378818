import { ChainId, FACTORY_ADDRESS_MAP, Percent, WNATIVE } from '@pancakeswap/sdk'
import FOT_DETECTOR_ABI from 'config/abi/taxDetector.json'
import { TaxDetector } from 'config/abi/types'
import { BIPS_BASE, ZERO_PERCENT } from 'config/constants/exchange'
import { useContract } from 'hooks/useContract'
import { useEffect, useState } from 'react'
import { getFeeOnTransferAddress } from 'utils/addressHelpers'
import { useActiveChainId } from './useActiveChainId'

function useFeeOnTransferDetectorContract(chainId?: ChainId): TaxDetector | null {
  const contract = useContract<TaxDetector>(getFeeOnTransferAddress(chainId), FOT_DETECTOR_ABI, true)

  return contract
}

const AMOUNT_TO_BORROW = 1000000000 // smallest amount that has full precision over bps

const FEE_CACHE: { [address in string]?: { sellTax?: Percent; buyTax?: Percent } } = {}

async function getSwapTaxes(
  fotDetector: TaxDetector,
  inputTokenAddress: string | undefined,
  outputTokenAddress: string | undefined,
  chainId: ChainId,
) {
  const addresses = []
  if (inputTokenAddress && FEE_CACHE[inputTokenAddress] === undefined) {
    addresses.push(inputTokenAddress)
  }

  if (outputTokenAddress && FEE_CACHE[outputTokenAddress] === undefined) {
    addresses.push(outputTokenAddress)
  }

  try {
    if (addresses.length) {
      const data = await fotDetector.callStatic.batchValidate(
        FACTORY_ADDRESS_MAP[chainId],
        addresses,
        WNATIVE[chainId].address,
        AMOUNT_TO_BORROW,
      )

      addresses.forEach((address, index) => {
        const { sellFeeBps, buyFeeBps } = data[index]
        const sellTax = new Percent(sellFeeBps.toNumber(), BIPS_BASE)
        const buyTax = new Percent(buyFeeBps.toNumber(), BIPS_BASE)

        FEE_CACHE[address] = { sellTax, buyTax }
      })
    }
  } catch (e) {
    console.error('useSwapTaxes', 'getSwapTaxes', 'Failed to get swap taxes for token(s):', addresses, e)
  }

  const inputTax = (inputTokenAddress ? FEE_CACHE[inputTokenAddress]?.sellTax : ZERO_PERCENT) ?? ZERO_PERCENT
  const outputTax = (outputTokenAddress ? FEE_CACHE[outputTokenAddress]?.buyTax : ZERO_PERCENT) ?? ZERO_PERCENT

  return { inputTax, outputTax }
}

// Use the buyFeeBps/sellFeeBps fields from Token GQL query where possible instead of this hook
export function useSwapTaxes(inputTokenAddress?: string, outputTokenAddress?: string, tokenChainId?: ChainId) {
  const { chainId: currChainId } = useActiveChainId()
  const chainId = tokenChainId ?? currChainId
  const fotDetector = useFeeOnTransferDetectorContract(chainId)
  const [{ inputTax, outputTax }, setTaxes] = useState({ inputTax: ZERO_PERCENT, outputTax: ZERO_PERCENT })

  useEffect(() => {
    if (!fotDetector || !chainId) {
      return
    }
    getSwapTaxes(fotDetector, inputTokenAddress, outputTokenAddress, chainId).then(setTaxes)
  }, [fotDetector, inputTokenAddress, outputTokenAddress, chainId])

  return { inputTax, outputTax }
}
