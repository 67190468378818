import { ChainId, Token, WBONE } from '@pancakeswap/sdk'
import { USDC_SHIB, USDT_SHIB } from './common'

export const shibariumTokens = {
  wbone: WBONE[ChainId.SHIBARIUM],
  usdc: USDC_SHIB,
  usdt: USDT_SHIB,
  chewy: new Token(
    ChainId.SHIBARIUM,
    '0x2761723006d3Eb0d90B19B75654DbE543dcd974f',
    18,
    'CHEWY',
    'ChewySwap',
    'https://chewyswap.dog/',
  ),
  leash: new Token(
    ChainId.SHIBARIUM,
    '0x65218A41Fb92637254B4f8c97448d3dF343A3064',
    18,
    'LEASH',
    'Doge Killer',
    'https://shib.io/',
  ),
  treat: new Token(
    ChainId.SHIBARIUM,
    '0x506d8d2d9c715Eb34F514cc3EF48C7aBD19e2bc7',
    18,
    'TREAT',
    'Shiba Inu Treat',
    'https://shib.io/tokens/treat',
  ),
  weth: new Token(ChainId.SHIBARIUM, '0x8ed7d143Ef452316Ab1123d28Ab302dC3b80d3ce', 18, 'WETH', 'Wrapped Ether', ''),
  shib: new Token(ChainId.SHIBARIUM, '0x495eea66B0f8b636D441dC6a98d8F5C3D455C4c0', 18, 'SHIB', 'Shiba Inu', ''),
}
