import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0x7c9320bb9Baf12C08D04dC2085E4AaBcC26084D5',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
    2000: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
    109: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
  },
  FeeOnTransfer: {
    109: '0x00a10c16724AF206A7D8FADa506ffb23091B5f0e',
    2000: '0x12B5Cb8D47Cfe2AFaF1B59846DDd82e4f9cceE71',
  },
  masterChefV1: {
    97: '',
    56: '',
    2000: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
    109: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x7c9320bb9Baf12C08D04dC2085E4AaBcC26084D5',
    2000: '',
    109: '',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    2000: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    109: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2000: '',
    109: '',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    2000: '',
    109: '0x5d74d0E0DD32037512574651f932213B0631D578',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    2000: '',
    109: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    2000: '',
    109: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    2000: '',
    109: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    2000: '',
    109: '',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    2000: '',
    109: '',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
    2000: '',
    109: '',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
    2000: '',
    109: '',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
    2000: '',
    109: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    2000: '',
    109: '',
  },
  cakeVault: {
    56: '0x5cd14Bb2244EcA1c7c2f6F97682Fe1b9a02146f1',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    2000: '0xDF21058099e69D3635005339721C4826c4c47F8A',
    109: '',
  },
  cakeFlexibleSideVault: {
    56: '0x7cdeA6aE7768Ee42E710E9B97601d8142956bb37',
    97: '',
    2000: '0xdd62bFb7447bd1aF04877438361693b2DB64D7f5',
    109: '',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    2000: '',
    109: '',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
    2000: '',
    109: '',
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
    109: '',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
    109: '',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
    109: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
    109: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
    109: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    109: '',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
    109: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    109: '',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    109: '',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    109: '0xe84d54d3C5c3c7A6B8D7931a0F332B6f83b4340a',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    109: '',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    109: '',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
    2000: '',
    109: '',
  },
  // TODO: multi
  zap: {
    56: '0x321Bf4EF31c87CF6E23E779db9C5F806A37d695F',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    2000: '0xaad6Ccd8Ee37b3f6a02B44f5585146C8543952b7',
    109: '0x8fFD42E2ebE98b4E5610C76Db9AF5e864cB35a5e',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
    109: '',
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
    2000: '0xdaAc51dB3429930D7B63abD3862E63112d7Af806',
    109: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
    2000: '0xeCd16F998484035c825EaAeD4F8BC6F8258B3A7e',
    109: '',
  },
}
